import styled from "styled-components";

const Wrapper = styled.section`
  .option-header {
    .form-row {
      margin-top: -1.25rem;
    }
  }

  /* ----- Other subform styling in SharedLayout.js ----- */

  /* ----- Edit Job Items ----- */

  .warranties .checkbox {
    border-radius: var(--borderRadius);
    .text-box {
      width: 88%;
      max-width: 80%;
    }
  }
  .warranties .selected {
    background-color: var(--yellow);
  }
  .warranties .form-row {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .fee,
  .discount {
    padding: 1rem;
    border-radius: var(--borderRadius);
  }
  .fees .selected {
    background-color: var(--red-light);
  }
  .discounts .selected {
    background-color: var(--green-light);
  }
  .option-header {
    .form-row {
      margin-top: -1.25rem;
    }
  }
  .edit-job-item .btn-block {
    column-gap: 0.1rem;
  }
  .edit-job-item .display {
    padding: 0 2em 0;
    margin: 4px;
  }
  .edit-job-item .checkbox {
    padding-top: 1.25rem;
  }
  .measurement-types {
    font-size: var(--text-small);
  }
  .input-checkbox {
    display: flex;
    align-items: baseline;
    min-width: 126px;
  }
  .deposit {
    display: flex;
    align-items: baseline;
    min-width: 550px;
  }
  .deposit .checkbox {
    display: flex;
  }
  .deposit .form-row {
    height: auto;
  }
  .deposit .radio {
    min-width: 7rem;
  }
  .quantity .btn {
    height: 31px;
    margin-top: 5px;
    width: 99%;
    display: block;
  }
  .measure-type {
    margin: 1rem 5%;
  }
  .content {
    background: var(--white);
    width: var(--fluid-width);
    height: 95vh;
    border-radius: var(--borderRadius);
    padding: 4rem 2rem;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .one-half {
    min-width: 260px;
  }
  .one-third {
    min-width: 220px;
  }

  .work-specs .edit-job-item .btn-block {
    margin-top: 0.5rem;
  }
  .option-header {
    .form-row {
      margin-top: -0.5rem;
    }
  }
  @media (max-width: 1280px) {
    .measure-type {
      margin: 1rem 25%;
    }
  }
  @media (min-width: 992px) {
    .measure-type {
      margin: 1rem 25%;
    }
  }
`;

export default Wrapper;
