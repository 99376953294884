import { useState } from "react";
import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/WorkSpecsPage";
import { Input, Select } from "../../components/dashboard";
import {
  WorkSpecsMain,
  JobItems,
  Photos,
  Warranties,
  FeesDiscounts,
} from "../../components/WorkSpecs";

function WorkSpecs() {
  const {
    job,
    options,
    indices,
    user,
    handleChange,
    handleArrayChange,
    handleArrayChanges,
    createRecord,
    clearArrayChanges,
    getArrayRecord,
    getPriceAdjustments,
    getWarranties,
    saveRecord,
    deleteRecord,
    loadMainRecords,
    isLoading,
  } = useAppContext();

  let index = indices.optionsIndex || 0;
  const maxUserRolesId = 3; // service rep

  const loadOptionData = async (i) => {
    handleChange({
      baseObject: { ...indices },
      name: "indices.optionsIndex",
      value: i,
    });
    getPriceAdjustments(`/price-adj/${options[i]?.id}`);
    getWarranties(`/warranties/${options[i]?.id}`);
    getArrayRecord(`/job-items/${options[i]?.id}`, "jobItems");
  };

  const handleClick = (e) => {
    // setIndex(e.target.value);
    handleChange({
      baseObject: { ...indices },
      name: "indices.optionsIndex",
      value: e.target.value,
    });
  };

  const handleInput = (e) => {
    const property = e.target.name;
    const value = e.target.value;
    const baseArray = [...options];
    const baseName = "options";
    handleArrayChange({ baseName, baseArray, index, property, value });
  };

  const createOption = async (e) => {
    e.preventDefault();
    // create new option
    const newOption = await createRecord(
      "/options/create",
      {
        core_job_data_id: job.id,
        title: `Option ${options.length + 1}`,
      },
      "option"
    );

    // add state value
    handleArrayChanges({
      baseName: "options",
      baseArray: [...options],
      index: options.length || 0,
      newValues: {
        id: newOption.id,
        core_job_data_id: job.id,
        title: `Option ${options.length + 1}`,
      },
    });

    // set optionsIndex
    console.log("attempting to set option index...");
    handleChange({
      baseObject: { ...indices },
      name: "indices.optionsIndex",
      value: options.length || 0,
    });
    index = options.length;
    // clear option specific values
    getPriceAdjustments(`/price-adj/undefined`); // undefined in path tells the function there are no values
    getWarranties(`/warranties/undefined`);
    clearArrayChanges({ baseName: "jobItems", defaultState: [] });
  };

  const selectOption = async (e) => {
    e.preventDefault();
    const value = options[index].id;
    handleChange({ baseObject: { ...job }, name: "job.options_id", value });
    // save job with new work description
    const newJobInfo = { ...job, options_id: value };
    await saveRecord(`/core-job/update/${job.id}`, newJobInfo, "job");
  };

  const deselectOption = async (e) => {
    e.preventDefault();
    handleChange({
      baseObject: { ...job },
      name: "job.options_id",
      value: null,
    });
    // save job with new work description
    const newJobInfo = { ...job, options_id: null };
    await saveRecord(`/core-job/update/${job.id}`, newJobInfo, "job");
  };

  const changeOption = (e) => {
    const value = e.target.value;
    setOption(value);
  };

  const removeOption = async () => {
    const path = `/options/delete/${option}`;
    await deleteRecord(path);
    // resets all relevant state values by reloading the current job
    loadMainRecords(job.id);
  };

  const [option, setOption] = useState("default");
  // set list of options or provide fallback object
  const optionList = options?.map((item) => {
    const id = item.id || 0;
    const textValue = item.title || "untitled";
    const text = id !== 0 ? textValue : "no saved option";
    return { id, text };
  });

  return (
    <Wrapper>
      <div className="page work-specs">
        {/* ----- Header ------ */}
        <div className="group whole option-header">
          <form className="form">
            <h3>{job?.option ? `${job.option}` : ""}</h3>
            <div className="form-center">
              <div className="one-half mobile-whole">
                <Input
                  type="text"
                  name="title"
                  labelText="Option Title"
                  value={options[index]?.title || ""}
                  handleChange={(e) => handleInput(e)}
                />
              </div>
              {/* only show for users with permission */}
              {user?.user_roles_id <= maxUserRolesId && (
                <div className="one-half flex-parent mobile-whole">
                  <div className="one-fourth">
                    {
                      /* don't show new option button when one is already chosen  */
                      !job.options_id && (
                        <button
                          name="newOption"
                          className="btn btn-block green align-button"
                          type="button"
                          onClick={createOption}
                          disabled={isLoading}
                        >
                          New Option
                        </button>
                      )
                    }
                  </div>

                  <div className="one-fourth">
                    {
                      /* don't show unneeded option controls  */
                      options.length > 1 && !job.options_id && (
                        <button
                          name="selectOption"
                          className="btn btn-block align-button"
                          type="button"
                          onClick={selectOption}
                          disabled={isLoading}
                        >
                          Select Option
                        </button>
                      )
                    }
                    {
                      /* only show when an option is actually selected  */
                      job.options_id && (
                        <button
                          name="deselectOption"
                          className="btn btn-block align-button"
                          type="button"
                          onClick={deselectOption}
                          disabled={isLoading}
                        >
                          Deselect Option
                        </button>
                      )
                    }
                  </div>
                </div>
              )}
              {
                /* only show when multiple options */
                options.length > 1 &&
                  !job.options_id &&
                  /* map through the options array and create a select button for each one */
                  options.map((option, i) => {
                    return (
                      <div className="one-fourth" key={i}>
                        <button
                          name={`Option ${i + 1}`}
                          value={i}
                          className="btn btn-block"
                          type="button"
                          onClick={(e) => {
                            loadOptionData(i);
                            handleClick(e);
                          }}
                          disabled={isLoading}
                        >
                          {`Option ${i + 1}`}
                        </button>
                      </div>
                    );
                  })
              }
            </div>
          </form>
        </div>

        {/* ----- Main Info ------ */}
        <WorkSpecsMain optionsIndex={index} />
        {/* ----- Before/After Photos ------ */}
        {user?.user_mode && (
          <>
            <Photos beforeAfter="Before" />
            <Photos beforeAfter="After" />
          </>
        )}
        {/* ----- Display Job Items ------ */}
        <JobItems optionsIndex={index} />
        {/* ----- Fees & Discounts------ */}
        <FeesDiscounts optionsIndex={index} />
        {/* ----- Warranties ------ */}
        <Warranties optionsIndex={index} />
        {/* ----- Delete Option ------ */}
        {
          /* don't show new option button when one is already chosen  */
          /* only show for managers and admins */
          !job.options_id && user?.user_roles_id <= maxUserRolesId && (
            <div className="group whole option-header">
              <form className="form">
                <div className="form-center">
                  <div className="three-fourths">
                    <Select
                      name="options"
                      labelText="Remove Option"
                      value={option}
                      handleChange={(e) => changeOption(e)}
                      options={optionList}
                    />
                  </div>
                  <div className="one-fourth">
                    <button
                      className="btn btn-block red align-button"
                      type="button"
                      disabled={isLoading}
                      onClick={removeOption}
                    >
                      {isLoading ? "please wait..." : "Delete Option"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )
        }
      </div>
    </Wrapper>
  );
}
export default WorkSpecs;
