import { useState } from "react";
import { useAppContext } from "../../context/appContext";
import ConfirmBox from "../ConfirmBox";
import PhotoModal from "./PhotoModal";

function Photos({ beforeAfter }) {
  const { files, job, saveRecord, handleChange, isLoading } = useAppContext();

  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [selectPhoto, setSelectPhoto] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const isBefore = beforeAfter === "Before";
  const photoGroup = isBefore ? job.before_photos : job.after_photos;

  const handleClick = (index) => {
    setSelectPhoto(index);
  };

  const handleAddPhoto = (e) => {
    setShowPhotoModal(!showPhotoModal);
  };

  const removePhoto = async () => {
    let newPhotos = [];
    // Remove photo from save list
    newPhotos = photoGroup.filter((_, i) => i !== selectPhoto);
    handleChange({
      baseObject: { ...job },
      name: isBefore ? "job.before_photos" : "job.after_photos",
      value: newPhotos,
    });
    // Update core job record in database
    const newJobInfo = isBefore
      ? { ...job, before_photos: newPhotos }
      : { ...job, after_photos: newPhotos };
    await saveRecord(`/core-job/update/${job.id}`, newJobInfo, "job");
    // Clean up
    setSelectPhoto(null);
  };

  const toggleRemoveBox = (id) => {
    setShowCancelModal(!showCancelModal);
  };

  // ----- Work Specs Main ------
  return (
    <div className="group one-half">
      <form className="form">
        <div className="form-center flex-parent">
          <h4 className="whole">{`${beforeAfter} Photos`}</h4>
          {/* Show Photos */}
          <div className="photos whole">
            {photoGroup?.map((photo, i) => {
              const photoFile = files?.filter((file) => file.name === photo);
              const thumbnail = photoFile[0]?.thumbnails[0]?.medium;
              return (
                <img
                  src={thumbnail?.url}
                  key={i}
                  className={
                    selectPhoto === i ? "selected-photo thumbnail" : "thumbnail"
                  }
                  height={178} // thumbnail.height
                  width={178} // thumbnail.width
                  onClick={() => handleClick(i)}
                  alt={`${photo} thumbnail`}
                />
              );
            })}
          </div>
          {/* Add Photo Button */}
          <div className="one-half">
            <button
              name={`add${beforeAfter}`}
              className="btn btn-block"
              type="button"
              onClick={handleAddPhoto}
              disabled={isLoading}
            >
              {isLoading ? "please wait..." : `Add ${beforeAfter} Photo`}
            </button>
          </div>
          {/* Remove Photo Button */}
          <div className="one-half">
            <button
              name={`deleteJob`}
              className="btn btn-block red"
              type="button"
              onClick={() => toggleRemoveBox()}
              disabled={isLoading}
            >
              Remove Photo
            </button>
          </div>
        </div>
      </form>
      {/* ----- Add Photo Modal ------ */}
      <div
        className={
          showPhotoModal ? "show-modal modal-container" : "modal-container"
        }
      >
        <div className="group">
          <PhotoModal
            name="confirmRemove"
            beforeAfter={beforeAfter}
            setShowPhotoModal={setShowPhotoModal}
          />
        </div>
      </div>
      {/* ----- Remove Photo Modal ------ */}
      <div
        className={
          showCancelModal ? "show-modal modal-container" : "modal-container"
        }
      >
        <div className="group">
          <ConfirmBox
            name="confirmRemove"
            confirmedFunction={removePhoto}
            message="Do you want to remove this photo?"
            setShowFunction={setShowCancelModal}
          />
        </div>
      </div>
    </div>
  );
}
export default Photos;
