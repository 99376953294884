import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppContext } from "../context/appContext.js";
import SharedWrapper from "../assets/wrappers/SharedLayout.js";
import { LargeLogo } from "../components";
import { ReportsOptionData } from "../components/Reports";
import { Display, Display1, StackedDisplay } from "../components/dashboard";

// http://localhost:3000/reports/1709
const Reports = () => {
  const {
    job,
    address,
    customer,
    contacts,
    options,
    files,
    user,
    loadMainRecords,
    getLists,
    defaults,
    getDefaults,
    getArrayRecord,
    getPriceAdjustments,
    getReportWarranties,
    handleChange,
  } = useAppContext();

  const { street_address, city, state, zip } = address;
  const date = new Date();
  const contact = contacts.filter(
    (contact) => contact.id.toString() === job.default_contact.toString()
  )[0];
  const { jobId } = useParams();
  const [reportOptions, setReportOptions] = useState();

  // grab url parameters
  let url_string = window.location.href;
  const url = new URL(url_string);
  const reportName = url.searchParams.get("reportName");
  const userMode = url.searchParams.get("userMode");
  // build date variable with timezone offset accounted for
  const baseDate = new Date(url.searchParams.get("reportDate"));
  const timeZoneOffset = baseDate.getTimezoneOffset() * 60 * 1000;
  const adjustedTimestamp = Date.parse(baseDate) + timeZoneOffset;
  const reportDate = new Date(adjustedTimestamp);
  const dateFormat = {
    weekday: undefined,
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const orderedFiles = files.sort(
    (a, b) => b.thumbnails[0]?.large?.width - a.thumbnails[0]?.large?.width
  );

  const initialize = async (jobId) => {
    // --- load lists and set default values first ---
    if (Object.keys(defaults).length === 0) {
      console.log("ran once...");
      console.log("jobId:", jobId);
      console.log("reportName:", reportName);
      await getDefaults();
      await loadMainRecords(jobId);
      await getLists();
    }
  };

  const getOptionData = async () => {
    console.log("attempting to build report options array...");
    let rprtOptions = [...options];
    console.log("reportOptions1:", rprtOptions);
    // - remove any extra options for invoices and receipts
    if (!reportName.includes("Proposal")) {
      const chosenOption = job.options_id
        ? // grab the selected option for the job if it exists
          options.filter((option) => option.id === job.options_id)[0]
        : // if no job selected then grab the first option
          options[0];
      rprtOptions = [{ ...chosenOption }];
    }
    console.log("reportOptions2:", rprtOptions);
    setReportOptions(
      await Promise.all(
        rprtOptions.map(async (option) => {
          console.log("optionsId:", option.id);
          const { fees, discounts } = await getPriceAdjustments(
            `/price-adj/${option.id}`
          );
          console.log("fees:", fees);
          console.log("discounts:", discounts);
          const warranties = await getReportWarranties(
            `/warranties/${option.id}`
          );
          console.log("warranties:", warranties);
          const jobItems = await getArrayRecord(
            `/job-items/${option.id}`,
            "jobItems"
          );
          console.log("jobItems:", jobItems);
          return {
            optn: option,
            jbItms: [...jobItems],
            wrrnts: [...warranties],
            dscnts: [...discounts],
            fs: [...fees],
          };
        })
      )
    );
    console.log("reportOptions3:", reportOptions);
    // reportOptions = [ {option0}, {option1}, {etc.}]
  };
  // first, determine user mode (live or sandbox)
  useEffect(() => {
    console.log("1st RUN");
    const baseObject = user;
    const name = "user.user_mode";
    const value = parseInt(userMode) || 2;
    console.log("userMode:", value);
    handleChange({ baseObject, name, value });
    // change usermode in local storage
    const localUserData = JSON.parse(localStorage.getItem("user", user));
    localUserData.user_mode = value ? Number(value) : value;
    console.log("local userMode:", localUserData);
    localStorage.setItem("user", JSON.stringify(localUserData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // second, load the core data
  useEffect(() => {
    console.log("2nd RUN");
    if (user.user_mode > 0) {
      console.log("user_mode:", user.user_mode);
      initialize(jobId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.user_mode]);
  // finally, load option specific data
  useEffect(() => {
    if (options.length > 0) {
      console.log("3rd RUN");
      getOptionData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  function Photos({ photosList }) {
    return (
      <div className="before-after-photos">
        {orderedFiles?.map((photo, i) => {
          let width, height, divClass;
          const thumbnail = photo.thumbnails[0]?.large;
          if (thumbnail?.width === 800) {
            width = 700; // fit to page
            height = 525;
            divClass = "whole four-by-three-image";
          } else {
            width = 366; // fit to half page
            height = 488;
            divClass = "one-half";
          }
          if (photosList.includes(photo.name)) {
            return (
              <div className={divClass}>
                <img
                  src={thumbnail?.url}
                  key={i}
                  className={"before-after-photo"}
                  height={height}
                  width={width}
                  alt={`${photo} thumbnail`}
                />
              </div>
            );
          }
        })}
      </div>
    );
  }

  return (
    <SharedWrapper>
      <main className="dashboard flex-parent report">
        {/* --- Header --- */}
        <div className="header whole flex-parent">
          <div className="one-half">
            <LargeLogo />
          </div>
          <div className="one-half">
            <div className="header-address">
              FreeLite Enterprises, Inc.
              <br />
              221 Garvon St
              <br />
              Garland, TX 75040
              <br />
              972-234-5990
            </div>
          </div>
          <div className="whole">
            <h1>{reportName}</h1>
          </div>
        </div>
        {/* --- Basic Info --- */}
        <div className="base-info flex-parent whole section">
          <div className="one-third">
            <Display name="Job #" value={job?.job_number} />
            <Display name="Name" value={customer?.customer_name} />
            <Display
              name="Date"
              value={reportDate.toLocaleDateString(undefined, dateFormat)}
            />
          </div>
          <div className="one-third">
            <Display1
              name="Address"
              value={`${street_address}\n${city}, ${state} ${zip}`}
            />
          </div>
          <div className="one-third">
            <Display name="Contact" value={contact?.contact_name || "N/A"} />
            <Display name="Email" value={contact?.email || "N/A"} />
            <Display name="Phone" value={contact?.phone || "N/A"} />
          </div>
        </div>
        {/* --- Work Description --- */}
        <div className="whole section">
          <h3>Work Description</h3>
          <Display1 value={job.work_description} />
        </div>
        {/* --- Before/After Photos --- */}
        {userMode === "1" && (
          <>
            <div className="whole section">
              <h3>Before Photos</h3>
              <Photos photosList={job?.before_photos} />
            </div>
            {!reportName.includes("Proposal") && (
              <div className="whole section">
                <h3>After Photos</h3>
                <Photos photosList={job?.after_photos} />
              </div>
            )}
          </>
        )}
        {/* --- Option Specific Data (jobitems, fees, discounts, warranties) --- */}
        {reportOptions?.map((option, index) => {
          return (
            <div key={index} className="whole option">
              <h2>
                {reportOptions.length > 1 && // only add when more than one option
                  option.optn.title}
              </h2>
              <ReportsOptionData
                optionVars={option}
                optionsId={option.id}
                reportName={reportName}
              />
            </div>
          );
        })}
        {/* --- Expiration Statement --- */}
        {reportName.includes("Proposal") && (
          <div>
            *Quote expires in 30 days. Final payment due upon completion.
          </div>
        )}
        {/* --- Signature / Date --- */}
        <div className="whole section flex-parent footer">
          <div className="one-half">
            <StackedDisplay name="Head of Operations" value="Marie McDonald" />
          </div>
          <div className="one-half">
            <StackedDisplay name="Date" value={date.toLocaleDateString()} />
          </div>
        </div>
      </main>
    </SharedWrapper>
  );
};

export default Reports;
